export default [
  {
    path: '/',
    component: () => import('../views/index.vue'),
    name: 'index',
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home.vue'),
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('../views/service/index.vue')
      },
      {
        path: '/service-detail',
        name: 'service-detail',
        component: () => import('../views/service/detail.vue')
      },
      {
        path: '/case-detail',
        name: 'case-detail',
        component: () => import('../views/service/case-detail.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/news.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
      },
      {
        path: '/new-detail',
        name: 'new-detail',
        component: () => import('../views/news/new-detail.vue')
      },
      {
        path: '/supplies-list',
        name: 'supplies-list',
        component: () => import('../views/supplies/list.vue')
      },
      {
        path: '/supplies-detail',
        name: 'supplies-detail',
        component: () => import('../views/supplies/detail.vue')
      },
      {
        path: '/purchase-list',
        name: 'purchase-list',
        component: () => import('../views/purchase/list.vue')
      },
      {
        path: '/purchase-detail',
        name: 'purchase-detail',
        component: () => import('../views/purchase/detail.vue')
      },
      {
        path: '/international-detail',
        name: 'international-detail',
        component: () => import('../views/international-detail.vue')
      },
      {
        path: '/meilan-detail',
        name: 'meilan-detail',
        component: () => import('../views/meilan-detail.vue')
      },
      {
        path: '/tkl-detail',
        name: 'tkl-detail',
        component: () => import('../views/tkl-detail.vue')
      },
      {
        path: '/lanyang-detail',
        name: 'lanyang-detail',
        component: () => import('../views/lanyang-detail.vue')
      },
      {
        path: '/capital-info',
        name: 'capital-info',
        component: () => import('../views/capital-info/list.vue')
      },
      {
        path: '/capital-detail',
        name: 'capital-detail',
        component: () => import('../views/capital-info/detail.vue')
      },
    ]
  }
]