import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},//用户信息
    applyStatus: '',//用户申请状态
    accountId: '',//account id
    //当前选择菜单
    activeMenu: '',
    faceCollect: false,
    faceVerify: false,
    tabName: 'home'
  },
  getters: {
  },
  mutations: {
    userInfo (state, user) {
      state.userInfo = user
    },
    applyStatus (state, val){
      state.applyStatus = val
    },
    accountId (state, val){
      state.accountId = val
    },
    updateActiveMenu (state, name) {
      state.activeMenu = name
    },
    faceCollect (state, val) {
      state.faceCollect = val
    },
    faceVerify (state, val) {
      state.faceVerify = val
    },
    updateTabName(state, val) {
      state.tabName = val
    }
  },
  actions: {
  },
  modules: {
  }
})
